import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { GoogleIdentityService } from '../services/identity.service';
import { MAS_Accounts_Service } from '../services/mas-accounts.service';

@Injectable({
	providedIn: 'root',
})
export class AccountResolverService implements Resolve<any> {
	constructor(private identityService: GoogleIdentityService, private accounts_Service: MAS_Accounts_Service) {}
	async resolve(): Promise<boolean> {
		console.log(this.identityService.isSignedIn);
		if (this.identityService.isSignedIn) {
			const data = await this.accounts_Service.getAccountsByEmail(this.identityService.isSignedIn);
			return data;
		} else {
			return false;
		}
	}
}
