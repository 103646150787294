import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-programs-yas',
	templateUrl: './programs-yas.component.html',
	styleUrls: ['./programs-yas.component.css'],
})
export class ProgramsYASComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
