import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { GoogleIdentityService } from 'src/app/services/identity.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-sign-up',
	templateUrl: './sign-up.component.html',
	styleUrls: ['./sign-up.component.css'],
})
export class SignUpComponent implements OnInit {
	@ViewChild('userPassword', { static: false }) elm!: ElementRef<HTMLInputElement>;

	constructor(public identityService: GoogleIdentityService, public router: Router) {}

	ngOnInit() {}

	SignUpButtonClick(email: string, password: string) {
		email = email.toLowerCase();
		this.identityService
			.signUpWithEmail(email, password)
			.then(() => {
				this.router.navigate(['account']);
			})
			.catch(error => {
				window.alert(error);
			});
	}

	keyDownEmail(event: { keyCode: number }) {
		if (event.keyCode === 13) {
			this.elm.nativeElement.focus();
		}
	}

	keyDownPassword(event: { keyCode: number }, email: string, password: string) {
		if (event.keyCode === 13) {
			this.SignUpButtonClick(email, password);
		}
	}
}
