/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { MAS_Accounts_Service } from 'src/app/services/mas-accounts.service';

@Component({
	selector: 'app-programs',
	templateUrl: './programs.component.html',
	styleUrls: ['./programs.component.css'],
})
export class ProgramsComponent implements OnInit {
	public tabMenu: MenuItem[];
	public tab: string = 'Classes';
	constructor(private accounts_Service: MAS_Accounts_Service, private accountsService: MAS_Accounts_Service, private router: Router) {
		if (this.accounts_Service.includeYAS) {
			this.tabMenu = [
				{
					label: 'Classes',
					command: () => {
						this.selectedTab('Classes');
					},
				},
				{
					label: 'Testing',
					command: () => {
						this.selectedTab('Testing');
					},
				},
				{
					label: 'YAS',
					command: () => {
						this.selectedTab('YAS');
					},
				},
			];
		} else {
			this.tabMenu = [
				{
					label: 'Classes',
					command: () => {
						this.selectedTab('Classes');
					},
				},
				{
					label: 'Testing',
					command: () => {
						this.selectedTab('Testing');
					},
				},
			];
		}
	}

	async ngOnInit() {
		if (!this.accountsService.myAccount) {
			this.router.navigate(['/']);
			return;
		}

		if (!this.accounts_Service.includeYAS) {
			this.tabMenu = [
				{
					label: 'Classes',
					command: () => {
						this.selectedTab('Classes');
					},
				},
				{
					label: 'Testing',
					command: () => {
						this.selectedTab('Testing');
					},
				},
			];
		}
	}

	selectedTab(tab: string) {
		this.tab = tab;
	}
}
