/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { MAS_Accounts_Service } from 'src/app/services/mas-accounts.service';

@Component({
	selector: 'app-info',
	templateUrl: './info.component.html',
	styleUrls: ['./info.component.css'],
})
export class InfoComponent implements OnInit {
	public tabMenu: MenuItem[];
	public tab: string = 'events';
	constructor(private accountsService: MAS_Accounts_Service, private router: Router) {
		this.tabMenu = [
			{
				label: 'Events',
				command: () => {
					this.selectedTab('events');
				},
			},
			/* 			{
				label: 'Activity',
				command: () => {
					this.selectedTab('activity');
				},
			}, */
			{
				label: 'Pie',
				command: () => {
					this.selectedTab('pie');
				},
			},
		];
	}
	async ngOnInit() {
		if (!this.accountsService.myAccount) {
			this.router.navigate(['/']);
			return;
		}
	}

	selectedTab(tab: string) {
		this.tab = tab;
	}
}
