import { Component, OnInit } from '@angular/core';
import { Contribution } from 'src/app/models/pie.model';
import { PIE_Contributions_Service } from 'src/app/services/pie-contributions.service';

@Component({
	selector: 'app-info-pie',
	templateUrl: './info-pie.component.html',
	styleUrls: ['./info-pie.component.css'],
})
export class InfoPieComponent implements OnInit {
	public contributions: Contribution[] = [];
	public totalDollars: number = 0;
	public totalHours: number = 0;
	constructor(public contributions_Service: PIE_Contributions_Service) {}
	ngOnInit() {
		this.getImpactBySchool();
	}

	async getImpactBySchool() {
		this.contributions = await this.contributions_Service.getImpact().then(result => {
			return result.map(m => {
				const data = m;
				data.id = m.id;
				return data;
			});
		});

		const dollars = this.contributions.map(m => m.dollars).filter(f => f >= 0);
		this.totalDollars = dollars.reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0);

		const hours = this.contributions.map(m => m.hours).filter(f => f >= 0);
		this.totalHours = hours.reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0);
	}
}
