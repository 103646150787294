import { NgModule } from '@angular/core';
import { AccordionModule } from 'primeng/accordion';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';
import { DockModule } from 'primeng/dock';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
	imports: [
		AccordionModule,
		BadgeModule,
		ButtonModule,
		CardModule,
		CheckboxModule,
		DividerModule,
		DockModule,
		MenuModule,
		MenubarModule,
		ProgressSpinnerModule,
		SelectButtonModule,
		TabMenuModule,
		TableModule,
		TooltipModule,
	],
	exports: [
		AccordionModule,
		BadgeModule,
		ButtonModule,
		CardModule,
		CheckboxModule,
		DividerModule,
		DockModule,
		MenuModule,
		MenubarModule,
		ProgressSpinnerModule,
		SelectButtonModule,
		TabMenuModule,
		TableModule,
		TooltipModule,
	],
})
export class NGPrimeModule {}
