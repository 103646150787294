import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Account } from 'src/app/models/mas-accounts.model';
import { ProgramAndSchedules } from 'src/app/models/mas-programs.model';
import { Classes, Notifications, Schedule, ScheduleElementType } from 'src/app/models/mas-schedules.model';
import { Firebase_Functions_Service } from 'src/app/services/functions.service';
import { MAS_Accounts_Service } from 'src/app/services/mas-accounts.service';
import { MAS_Programs_Service } from 'src/app/services/mas-programs.service';
import { MAS_Schedules_Service } from 'src/app/services/mas-schedules.service';

@Component({
	selector: 'app-programs-classes',
	templateUrl: './programs-classes.component.html',
	styleUrls: ['./programs-classes.component.css'],
})
export class ProgramsClassesComponent implements OnInit {
	public classes: any[] = [];
	public spinnerState = true;

	constructor(
		private accounts_Service: MAS_Accounts_Service,
		private firebaseFunctions: Firebase_Functions_Service,
		private programs_Service: MAS_Programs_Service,
		private schedules_Service: MAS_Schedules_Service,
		public router: Router
	) {}

	async ngOnInit() {
		const linkedAccounts = this.accounts_Service.linkedAccounts;
		const programsList = this.accounts_Service.myProgramsList;

		//^ Get programs and schedules
		let programs: any[] = await this.programs_Service.getProgramsAndSchedules(programsList).catch(error => {
			this.firebaseFunctions.sendErrorEmail({ summary: 'programs-classes.component.ts => ngOnInit => getProgramsAndSchedules threw and error', detail: error });
			return [] as ProgramAndSchedules[];
		});

		//^ If my programs have no schedules then there cannot be any classes
		if (programs?.length === 0) {
			this.spinnerState = false;
			this.classes = [];
			return;
		}

		const allAccounts = linkedAccounts?.length > 0 ? linkedAccounts : [];

		if (this.accounts_Service.myAccount.mas.accountSettings.member === 'true') allAccounts.push(this.accounts_Service.myAccount);

		this.classes = await this.processAccounts(programs, allAccounts);

		this.spinnerState = false;
	}

	async processAccounts(programs: ProgramAndSchedules[], accounts: Account[]) {
		const classes = accounts.map(async account => {
			return await this.buildClassObject(programs, account).catch(error => {
				console.log(error, programs);
				this.firebaseFunctions.sendErrorEmail({
					summary: 'programs-classes.component.ts => addLinksToClasses => buildClassObject threw and error',
					detail: error,
				});
				return [];
			});
		});

		return (await Promise.all(classes)).flat();
	}

	async buildClassObject(programs: ProgramAndSchedules[], account: Account) {
		const accountMembership = account.memberships ?? [];

		const filteredPrograms = programs.filter(f => (accountMembership.indexOf(f.id as string) > -1 ? true : false));

		// create a copy so that each account isEnrolled has its own memory space
		const classes = filteredPrograms.map(m => {
			const clonedArray: ScheduleElementType[] = JSON.parse(JSON.stringify(m.schedules));

			clonedArray.map(schedule => {
				const searchAttendance = schedule.attendance?.filter(f => f.id === account.id).pop();

				schedule.enrolled = searchAttendance ? true : false;
				schedule.icon = schedule.enrolled ? 'pi pi-check-circle' : 'pi pi-times-circle';
				schedule.css = schedule.enrolled ? 'selected' : 'unselected';

				if (schedule.enrolled) {
					schedule.disable = false;
				} else if (schedule.attendance?.length >= m.reserveLimit) {
					schedule.disable = true;
				}
			});

			const classesElement: Classes = {
				tableId: `${account.id}-${m.id}`,
				displayName: `${account.names.givenName} ${account.names.familyName}`,
				programName: m.name as string,
				programId: m.id as string,
				accountId: account.id as string,
				reserveLimit: m.reserveLimit,
				schedules: clonedArray as unknown as ScheduleElementType[],
			};

			return classesElement;
		});

		return classes;
	}

	async onClick(klass: Classes, schedIndex: number, disable: boolean) {
		//^ let's not be click happy
		if (this.spinnerState || disable) return;

		this.spinnerState = true;

		const schedule = <ScheduleElementType>klass.schedules[schedIndex];

		if (!schedule.enrolled) {
			await this.enrollInClass(klass.accountId, klass.displayName, schedule.id).catch(error => {
				this.firebaseFunctions.sendErrorEmail({ summary: 'programs-classes.component.ts => onClick => enrollInClass threw and error', detail: error });
			});
		} else {
			await this.unenrollInClass(klass.accountId, schedule.id).catch(error => {
				this.firebaseFunctions.sendErrorEmail({ summary: 'programs-classes.component.ts => onClick => enrollInClass threw and error', detail: error });
			});
		}

		await this.ngOnInit();

		this.spinnerState = false;
	}

	async enrollInClass(accountId: string, accountName: string, scheduleId: string) {
		let givenDocument: Schedule;

		await this.schedules_Service
			.getScheduleById(scheduleId)
			.then(doc => {
				const value: Notifications = {
					id: this.accounts_Service.myAccount.id,
					name: `${this.accounts_Service.myAccount.names.givenName} ${this.accounts_Service.myAccount.names.familyName}`,
				};

				const sms = this.accounts_Service.myAccount.mas.accountSettings.enableSMS;
				if (sms) value.phone = this.accounts_Service.myAccount.phoneNumbers?.value;

				const email = this.accounts_Service.myAccount.mas.accountSettings.enableEmailReminders;
				if (email) value.email = this.accounts_Service.myAccount.emailAddresses?.value;

				givenDocument = doc as Schedule;

				if (givenDocument?.attendance) {
					//Test for duplicate enrollment
					const attendanceList: string[] = doc?.attendance?.map(m => m.id) ?? [];
					const checkForDuplicate: boolean = attendanceList.includes(accountId);
					if (checkForDuplicate) throw new Error(`${accountName} is enrolled in class`);

					//If there is not duplicate add to attendance
					givenDocument.attendance.push({ attended: false, id: accountId, name: accountName, reserved: true, notifications: value });
				} else {
					givenDocument.attendance = [{ attended: false, id: accountId, name: accountName, reserved: true, notifications: value }];
				}
			})
			.catch(error => console.log(error))
			.finally(() => {
				this.schedules_Service.setScheduleById(scheduleId, givenDocument);
			});
	}

	async unenrollInClass(accountId: string, scheduleId: string) {
		let givenDocument: Schedule;
		await this.schedules_Service
			.getScheduleById(scheduleId)
			.then(doc => {
				givenDocument = doc as Schedule;

				const attendanceFilter = givenDocument.attendance?.filter(m => m.id !== accountId);
				givenDocument.attendance = attendanceFilter;
			})
			.finally(() => {
				this.schedules_Service.setScheduleById(scheduleId, givenDocument);
			});
	}
}
