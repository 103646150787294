export interface Classes {
	accountId: string;
	displayName: string;
	programId: string;
	programName: string;
	reserveLimit: number;
	schedules: ScheduleElementType[];
	tableId: string;
}

export interface DisplaySchedule extends Schedule {
	startTime: string;
	endTime: string;
}

export interface Schedule {
	attendance?: Attendance[];
	cycleName: string;
	end: { dateTime: string; timeZone: string };
	eventType: string;
	id: string;
	notifications?: Notifications[];
	recurringEventId?: string | null;
	start: { dateTime: string; timeZone: string };
	summary: string;
	viewSettings?: {
		attendanceCount?: number;
		buttonDisabled?: boolean;
		duplicates?: boolean;
		hasJoined?: boolean;
		memberReservedIndex?: number;
		optionReserve?: boolean;
		reservationCount?: number;
	};
}

export interface ScheduleElementType {
	attendance: Attendance[];
	css: 'selected' | 'unselected';
	cycleName: string;
	disable: boolean;
	enrolled: boolean;
	icon: 'pi pi-times-circle' | 'pi pi-check-circle';
	id: string;
	reserveLimit: number;
	startDateTime: string;
	testingLimits: number;
}

export interface Notifications {
	email?: string;
	id: string;
	name: string;
	phone?: string;
}

export interface Attendance {
	attendanceTotal?: number;
	attended: boolean;
	id: string;
	name: string;
	notifications?: Notifications;
	reserved: boolean;
	tournaments?: string;
}
