<p-progressSpinner *ngIf="spinnerState" class="spinner"></p-progressSpinner>
<p-table [scrollable]="false" [value]="classes" dataKey="tableId" styleClass="p-datatable-gridlines p-datatable-striped">
	<ng-template pTemplate="body" let-class let-expanded="expanded">
		<tr>
			<td style="text-align: left" class="flex">
				<div style="width: 90%">{{ class.programName }} for {{ class.displayName }}</div>
				<div>
					<button type="button" pButton pRipple [pRowToggler]="class" class="p-button-text p-button-rounded p-button-plain"
						[icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" style="margin: 0px"></button>
				</div>
			</td>
		</tr>
	</ng-template>
	<ng-template pTemplate="rowexpansion" let-class let-classIndex="rowIndex">
		<tr>
			<td>
				<p-table [scrollable]="false" [value]="class.schedules" dataKey="summary" styleClass="p-datatable-gridlines p-datatable-striped">
					<ng-template pTemplate="body" let-schedule let-scheduleIndex="rowIndex">
		<tr>
			<td>
				<p-button [styleClass]="schedule.css" [icon]="schedule.icon" badge="{{ class.reserveLimit - schedule.attendance?.length }}" label="{{ schedule.startDateTime }}"
					(click)="onClick(class, scheduleIndex, schedule.disabled)" [disabled]="spinnerState  || schedule.disable"></p-button>
			</td>
		</tr>
	</ng-template>
</p-table>
</td>
</tr>
</ng-template>
</p-table>