import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-info-lifeskills',
	templateUrl: './info-lifeskills.component.html',
	styleUrls: ['./info-lifeskills.component.css'],
})
export class InfoLifeSkillsComponent implements OnInit {
	constructor() {}
	ngOnInit(): void {}
}
