import { Injectable } from '@angular/core';
import {
	addDoc,
	collection,
	collectionGroup,
	CollectionReference,
	collectionSnapshots,
	deleteDoc,
	doc,
	DocumentData,
	DocumentReference,
	Firestore,
	getDoc,
	getDocs,
	limit,
	orderBy,
	Query,
	query,
	setDoc,
	updateDoc,
	where,
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Account, AccountAttendance, AttendanceViolation, TournamentParticipation } from '../models/mas-accounts.model';
import { Program } from '../models/mas-programs.model';
import { GoogleIdentityService } from './identity.service';
import { TournamentStanding } from '../models/utilities.model';

@Injectable({
	providedIn: 'root',
})
export class MAS_Accounts_Service {
	public selectedNode!: Account;
	public memberId!: string;
	public memberName!: string;
	public billingEmail!: string | null;
	public myAccount!: Account;
	public linkedAccounts: Account[] = [];
	public includeYAS: boolean = false;
	public myProgramsList: string[] = [];

	constructor(private firestore: Firestore, private identityService: GoogleIdentityService) {}

	checkAccountQuality(account: Account): Promise<any> {
		return new Promise((resolve, reject) => {
			const errors = [];

			// Check for nodeType existence
			if (!account.mas.nodeType) {
				errors.push('Account missing nodeType.');
			}

			// Check each linked account for accountId
			account.mas.linkedAccounts?.forEach(link => {
				if (!link.accountId) {
					errors.push('Linked account missing accountId.');
				}
			});

			// Check account status
			const validStatuses = ['Active', 'Inactive', 'New', 'Pending', 'Trial'];
			if (!validStatuses.includes(account.mas.accountSettings.status)) {
				errors.push('Account status is invalid.');
			}

			if (account.memberships) {
				const uniqueMemberships = new Set(account.memberships);
				if (uniqueMemberships.size !== account.memberships.length) {
					if (window.location.hostname == 'localhost') console.log('Duplicate membership found. Attempting to resolve.');
					account.memberships = Array.from(uniqueMemberships);
					this.setAccount(account.id, account, 'existing');
				}
			}

			if (errors.length === 0) {
				resolve({ accepted: true, msg: 'No errors' });
			} else {
				reject({ accepted: false, msg: errors.join(' ') });
			}
		});
	}

	async createAccount(payload: Account): Promise<DocumentReference<DocumentData>> {
		const ref = collection(this.firestore, 'mas-accounts');
		return await addDoc(ref, payload).then(doc => doc);
	}

	async deleteAccountAttendance(memberId: string, documentId: string): Promise<void> {
		const ref = doc(this.firestore, `mas-accounts/${memberId}/mas-accounts-attendance`, documentId);
		return await deleteDoc(ref).then(doc => doc);
	}

	async getAccounts(): Promise<Account[]> {
		const q = query<Account, DocumentData>(collection(this.firestore, 'mas-accounts') as CollectionReference<Account>);
		return await getDocs(q).then(data =>
			data.docs.map(m => {
				const data = m.data();
				data.id = m.id;
				return data;
			})
		);
	}

	async archiveCollection(path: string): Promise<void> {
		try {
			const q = query<any, DocumentData>(collection(this.firestore, path) as CollectionReference<any>);
			const data = await getDocs(q);

			const archivePromises = data.docs.map(async m => {
				const ref = doc(this.firestore, `${path}-archive`, m.id);
				await setDoc(ref, m.data());
			});

			await Promise.all(archivePromises);
			console.log(`Successfully archived collection at path: ${path}`);
		} catch (error) {
			console.error(`Error archiving collection at path: ${path}`, error);
			throw new Error(`Failed to archive collection at path: ${path}`);
		}
	}

	async getAccountAttendance(id: string, programs: string[]): Promise<AccountAttendance[]> {
		const q = query<AccountAttendance, DocumentData>(
			collection(this.firestore, `mas-accounts/${id}/mas-accounts-attendance/`) as CollectionReference<AccountAttendance>,
			where('scheduleName', 'in', programs)
		);

		const qDocs = await getDocs(q);

		if (!qDocs) return [];

		return qDocs.docs.map(m => m.data());
	}

	async getAccountViolations(id: string, cycleName?: string): Promise<AttendanceViolation[]> {
		let q: Query<AttendanceViolation>;

		if (cycleName) {
			q = query<AttendanceViolation, DocumentData>(
				collection(this.firestore, `mas-accounts/${id}/mas-accounts-violation/`) as CollectionReference<AttendanceViolation>,
				where('cycleName', '==', cycleName)
			);
		} else {
			q = query<AttendanceViolation, DocumentData>(collection(this.firestore, `mas-accounts/${id}/mas-accounts-violation/`) as CollectionReference<AttendanceViolation>);
		}

		const qDocs = await getDocs(q);

		if (!qDocs) return [];

		return qDocs.docs.map(m => {
			const data = m.data();
			data.id = m.id;
			return data;
		});
	}

	async getAccountAttendanceCount(id: string, programs: string[], cycle: string) {
		const q = query<AccountAttendance, DocumentData>(
			collection(this.firestore, `mas-accounts/${id}/mas-accounts-attendance/`) as CollectionReference<AccountAttendance>,
			where('scheduleName', 'in', programs),
			where('cycleName', '==', cycle)
		);

		const qDocs = await getDocs(q);

		if (!qDocs) return [];

		return qDocs.docs.map(m => m.data());
	}

	async getAccountTournaments(id: string) {
		const q = query<TournamentParticipation, DocumentData>(collection(this.firestore, `mas-accounts/${id}/mas-accounts-tournaments/`) as CollectionReference<TournamentParticipation>);

		const qDocs = await getDocs(q);

		if (!qDocs) return [];

		return qDocs.docs.map(m => m.data());
	}

	async getAccountStandings(id: string) {
		const q = query<TournamentStanding, DocumentData>(collection(this.firestore, `mas-accounts/${id}/mas-accounts-standings/`) as CollectionReference<TournamentStanding>);

		const qDocs = await getDocs(q);

		if (!qDocs) return [];

		return qDocs.docs.map(m => m.data());
	}

	async getAccountAttendanceById(accountId: string, scheduleId: string): Promise<Boolean> {
		const docRef = doc(this.firestore, `mas-accounts/${accountId}/mas-accounts-attendance/`, scheduleId);
		const docSnap = await getDoc(docRef);
		return docSnap.exists();
	}

	getAccountAttendanceByProgram(accountId: string, program: string): Promise<number> {
		const q = query(collection(this.firestore, `mas-accounts/${accountId}/mas-accounts-attendance/`), where('scheduleName', '==', program));
		return getDocs(q).then(data => {
			return data.size;
		});
	}

	//^ my app.component service properties setup
	async getAccountsByEmail(email: string) {
		//^ fetch my account
		const q = query<Account, DocumentData>(
			collection(this.firestore, 'mas-accounts') as CollectionReference<Account>,
			where('emailAddresses.value', '==', email),
			where('mas.nodeType', '==', 'parent')
		);

		const qDocs = await getDocs(q);

		//! set my account as a service property
		this.myAccount = <Account>qDocs!.docs.pop()?.data();

		if (!this.myAccount) {
			console.log('invalidEmail');
			this.identityService.signOut();
			window.alert('invalid account');
		}

		//^ fetch linked accounts to an account array?
		const MyAccounts = this.myAccount.mas.linkedAccounts?.map(m => m.accountId) ?? [];

		//! set account array service property
		this.linkedAccounts = (await this.getAccountsById(MyAccounts)) ?? [];

		//^ we need the YAS program members
		const qYAS = query<Program, DocumentData>(collection(this.firestore, 'mas-programs') as CollectionReference<Program>, where('name', '==', 'YAS'));

		const yasDocs = await getDocs(qYAS);

		//^ we have the YAS program members
		const yasMembership = yasDocs!.docs
			.pop()
			?.data()
			.memberships?.map(m => m.memberId);

		//^ are any of my accounts members of YAS?
		MyAccounts.push(this.myAccount.id);

		const intersection = yasMembership?.filter(value => MyAccounts?.includes(value)) ?? [];

		//! set includeYAS service property
		this.includeYAS = intersection?.length > 0;

		//! set myPrograms service property
		const programsLinks = <string[]>this.linkedAccounts.map(m => m.memberships).flat() ?? [];
		const programsMy = this.myAccount.memberships ?? [];
		const programAll = programsMy.concat(programsLinks);
		this.myProgramsList = [...new Set(programAll)];

		//! give caller a nod
		return true;
	}

	async getAccountByEmail(email: string): Promise<Account[]> {
		const q = query<Account, DocumentData>(
			collection(this.firestore, 'mas-accounts') as CollectionReference<Account>,
			where('emailAddresses.value', '==', email),
			where('mas.accountSettings.billing', '==', 'true'),
			limit(1)
		);
		return await getDocs(q).then(data =>
			data.docs.map(m => {
				this.myAccount = m.data();
				return m.data();
			})
		);
	}

	async getAccountById(id: string): Promise<Account> {
		const docRef = doc(this.firestore, 'mas-accounts', id);
		const docSnap = await getDoc(docRef);
		return docSnap.data() as Account;
	}

	async getAccountsByLinked(links: string[]) {
		const q = query<Account, DocumentData>(collection(this.firestore, 'mas-accounts') as CollectionReference<Account>, where('id', 'in', links));

		const qDocs = await getDocs(q);

		if (!qDocs) return [];

		return qDocs.docs.map(m => m.data());
	}

	getAccountTrivaData(): Observable<Account[]> {
		return collectionSnapshots<Account>(
			query<Account, DocumentData>(
				collection(this.firestore, 'mas-accounts') as CollectionReference<Account>,
				where('mas.triviaQuestionData.cumulativeCorrect', '>', 0),
				where('mas.accountSettings.status', 'in', ['Active', 'Pending', 'Trial']),
				orderBy('mas.triviaQuestionData.cumulativeCorrect')
			)
		).pipe(
			map(changes => {
				return changes.map(a => {
					const data = a.data();
					data.id = a.id;
					return data;
				});
			})
		);
	}
	async getAccountsByActiveBillingStatus(): Promise<Account[]> {
		const q = query<Account, DocumentData>(
			collection(this.firestore, 'mas-accounts') as CollectionReference<Account>,
			where('mas.accountSettings.status', '==', 'Active'),
			where('mas.accountSettings.billing', '==', 'true'),
			orderBy('names.givenName')
		);

		const qDocs = await getDocs(q);

		if (!qDocs) return [];

		return qDocs.docs.map(m => m.data());
	}

	async getAccountsByBillingStatus(billing: 'true' | 'false' = 'true') {
		const q = query<Account, DocumentData>(
			collection(this.firestore, 'mas-accounts') as CollectionReference<Account>,
			where('mas.accountSettings.billing', '==', billing),
			orderBy('names.givenName')
		);

		const qDocs = await getDocs(q);
		if (!qDocs) return [];

		return qDocs.docs.map(m => m.data());
	}

	getAccountsByActiveMember(): Observable<Account[]> {
		console.log('members component ngOnInit');
		return collectionSnapshots<Account>(
			query<Account, DocumentData>(
				collection(this.firestore, 'mas-accounts') as CollectionReference<Account>,
				where('mas.accountSettings.status', 'in', ['Active', 'Pending', 'Trial']),
				where('mas.accountSettings.member', '==', 'true'),
				orderBy('names.givenName')
			)
		).pipe(
			map(accountChanges => {
				return accountChanges.map(accounts => {
					const account = accounts.data() as Account;
					account.id = accounts.id;
					return account;
				});
			})
		);
	}

	async getAccountsByMemberAsPromise(): Promise<Account[]> {
		const q = query<Account, DocumentData>(collection(this.firestore, 'mas-accounts') as CollectionReference<Account>, where('mas.accountSettings.member', '==', 'true'));

		const qDocs = await getDocs(q);

		if (!qDocs) return [];

		return qDocs.docs.map(m => m.data());
	}

	getAccountsByActiveParent(): Observable<Account[]> {
		return collectionSnapshots<Account>(
			query<Account, DocumentData>(
				collection(this.firestore, 'mas-accounts') as CollectionReference<Account>,
				where('mas.accountSettings.status', '!=', 'Inactive'),
				where('mas.nodeType', '==', 'parent')
			)
		).pipe(
			map(changes => {
				return changes.map(a => {
					const data = a.data();
					data.id = a.id;
					return data;
				});
			})
		);
	}

	getAccountsByActiveStatus(): Observable<Account[]> {
		return collectionSnapshots<Account>(
			query<Account, DocumentData>(collection(this.firestore, 'mas-accounts') as CollectionReference<Account>, where('mas.accountSettings.status', '!=', 'Inactive'))
		).pipe(
			map(changes => {
				return changes.map(a => {
					const data = a.data();
					data.id = a.id;
					return data;
				});
			})
		);
	}

	async getAccountsById(idList: string[]): Promise<Account[]> {
		if (idList.length === 0) return [];
		const q = query<Account, DocumentData>(collection(this.firestore, 'mas-accounts') as CollectionReference<Account>, where('id', 'in', idList));

		const qDocs = await getDocs(q);

		if (!qDocs) return [];

		return qDocs.docs.map(m => m.data());
	}

	getAccountsByInactiveStatus(): Observable<Account[]> {
		return collectionSnapshots<Account>(
			query<Account, DocumentData>(collection(this.firestore, 'mas-accounts') as CollectionReference<Account>, where('mas.accountSettings.status', '==', 'Inactive'))
		).pipe(
			map(changes => {
				return changes.map(a => {
					const data = a.data();
					data.id = a.id;
					return data;
				});
			})
		);
	}

	async getAccountsByLinkedProperties(searchProperties: any): Promise<Account[]> {
		const q = query<Account, DocumentData>(collection(this.firestore, 'mas-accounts') as CollectionReference<Account>, where('mas.linkedAccounts', 'array-contains', searchProperties));

		const qDocs = await getDocs(q);

		if (!qDocs) return [];

		return qDocs.docs.map(m => m.data());
	}

	async getAccountsByProgram(programId: string): Promise<Account[]> {
		const q = query<Account, DocumentData>(collection(this.firestore, 'mas-accounts') as CollectionReference<Account>, where('memberships', 'array-contains', programId));

		const qDocs = await getDocs(q);

		if (!qDocs) return [];

		return qDocs.docs.map(m => m.data());
	}

	async getAccountsBySchool(school: string): Promise<Account[]> {
		const q = query<Account, DocumentData>(
			collection(this.firestore, 'mas-accounts') as CollectionReference<Account>,
			where('mas.accountSettings.school', '==', school),
			where('mas.accountSettings.status', '==', 'Active'),
			where('mas.accountSettings.member', '==', 'true'),
			orderBy('names.givenName')
		);

		const qDocs = await getDocs(q);
		if (!qDocs) return [];

		return qDocs.docs.map(m => m.data());
	}

	async getAccountsWithSchools(): Promise<Account[]> {
		const q = query<Account, DocumentData>(
			collection(this.firestore, 'mas-accounts') as CollectionReference<Account>,
			where('mas.accountSettings.school', '>', ''),
			where('mas.accountSettings.status', '==', 'Active'),
			where('mas.accountSettings.member', '==', 'true'),
			orderBy('mas.accountSettings.school')
		);

		const qDocs = await getDocs(q);

		if (!qDocs) return [];

		return qDocs.docs.map(m => m.data());
	}

	async getBillingEmail(id: string): Promise<any> {
		const docRef = doc(this.firestore, 'mas-accounts', id);
		const docSnap = await getDoc(docRef);

		const billing = docSnap.data() as Account;
		if (billing.emailAddresses?.value) {
			return billing.emailAddresses?.value;
		} else {
			return null;
		}
	}

	async getReservedEmails(childArray: string[]): Promise<any> {
		const childBatches = [];
		const childDocs: Account[] = [];
		const childDocsSnaps: DocumentData[] = [];

		const parentArray: string[] = [];
		const parentBatches = [];
		const parentDocsSnaps: DocumentData[] = [];
		const parentDocs: Account[] = [];

		const emailArray: string[] = [];

		while (childArray.length) {
			const batch = childArray.splice(0, 10);
			childBatches.push(query(collection(this.firestore, 'mas-accounts'), where('id', 'in', [...batch])));
		}

		for (let i = 0; i < childBatches.length; i++) {
			childDocsSnaps.push(getDocs(childBatches[i]));

			await getDocs(childBatches[i])
				.then(results => results.docs)
				.then(docs => {
					docs.forEach(doc => {
						const data = doc.data() as Account;
						childDocs.push(data);
					});
				});
		}

		childDocs.forEach(doc => {
			const parent = doc.mas.linkedAccounts
				?.filter(f => f.type === 'parent')
				.map(m => m)
				.pop()?.accountId;

			if (doc.emailAddresses?.value) emailArray.push(doc.emailAddresses.value);
			if (parent) parentArray.push(parent);
		});

		while (parentArray.length) {
			const batch = parentArray.splice(0, 10);
			parentBatches.push(query(collection(this.firestore, 'mas-accounts'), where('id', 'in', [...batch])));
		}

		for (let i = 0; i < parentBatches.length; i++) {
			parentDocsSnaps.push(getDocs(parentBatches[i]));

			await getDocs(parentBatches[i])
				.then(results => results.docs)
				.then(docs => {
					docs.forEach(doc => {
						const data = doc.data() as Account;
						parentDocs.push(data);
					});
				});
		}
		return [].concat(emailArray as [], parentDocs.map(m => m.emailAddresses?.value) as []);
	}

	handleSMSReminders(option: boolean) {
		this.myAccount.mas.accountSettings.enableSMS = option;
		const ref = doc(this.firestore, 'mas-accounts', this.myAccount.id);
		return setDoc(ref, this.myAccount, { merge: true });
	}

	handleEmailReminders(option: boolean) {
		this.myAccount.mas.accountSettings.enableEmailReminders = option;
		const ref = doc(this.firestore, 'mas-accounts', this.myAccount.id);
		return setDoc(ref, this.myAccount, { merge: true });
	}

	async setAccount(id: string, payload: Account, mode: 'existing' | 'other'): Promise<Account> {
		const existingAccount = mode === 'existing' ? true : false;
		const ref = doc(this.firestore, 'mas-accounts', id);
		await setDoc(ref, payload, { merge: existingAccount }).then(doc => doc);
		return payload;
	}

	async setAccountAttendance(id: string, payload: any): Promise<void> {
		const ref = doc(this.firestore, `mas-accounts/${id}/mas-accounts-attendance`, payload.scheduleId);
		return await setDoc(ref, payload, { merge: true });
	}

	async setAccountViolation(id: string, payload: any): Promise<void> {
		const ref = doc(this.firestore, `mas-accounts/${id}/mas-accounts-violation`, payload.scheduleId);
		return await setDoc(ref, payload, { merge: true });
	}

	async setAccountTrivia(id: string, payload: any): Promise<void> {
		const ref = doc(this.firestore, `mas-accounts/${id}/mas-accounts-trivia`, payload.lastDate);
		return await setDoc(ref, payload, { merge: true }).then(doc => doc);
	}

	async updateAccount(id: string, payload: any): Promise<void> {
		const ref = doc(this.firestore, 'mas-accounts', id);
		return await updateDoc(ref, payload).then(doc => doc);
	}

	async getAttendanceData(id: string) {
		const q = query(collectionGroup(this.firestore, 'mas-accounts-attendance'), where('scheduleId', '>=', id), limit(500));

		const attendance = (await getDocs(q)).docs.map(m => {
			const data = m.data();
			data['id'] = m.id;

			data['parent'] = m.ref.parent.parent?.id;
			return data;
		});
		return attendance as { cycleName: string; parent: string; scheduleId: string; scheduleName: string }[];
	}

	async getLastTournament(): Promise<{ tournamentDate: string; tournamentName: string }> {
		const q = query(collectionGroup(this.firestore, 'mas-accounts-tournaments'));

		const tournaments = (await getDocs(q)).docs.map(m => {
			// Explicitly type the data
			const data = m.data() as { [key: string]: any };
			return {
				tournamentDate: data['tournamentDate'],
				tournamentName: data['tournamentName'],
			};
		});

		// Sort tournaments by date in ascending order
		tournaments.sort((a, b) => new Date(a.tournamentDate).getTime() - new Date(b.tournamentDate).getTime());

		// Return the last tournament
		const lastTournament = tournaments[tournaments.length - 1];
		return lastTournament;
	}

	async deleteCorruptAttendance(path: string, id: string) {
		const ref = doc(this.firestore, path, id);
		return await deleteDoc(ref);
	}

	async deleteAccountViolation(memberId: string, documentId: string): Promise<void> {
		const ref = doc(this.firestore, `mas-accounts/${memberId}/mas-accounts-violation`, documentId);
		return await deleteDoc(ref).then(doc => doc);
	}

	async getBillingAccount(account: Account) {
		try {
			if (account.mas.accountSettings.billing === 'false') {
				const parent = account.mas.linkedAccounts?.filter(f => f.type === 'parent');
				if (!parent) throw new Error('missing billing account');
				return await this.getAccountById(parent[0].accountId);
			} else {
				return account;
			}
		} catch (error) {
			throw error;
			return;
		}
	}
}
