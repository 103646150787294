/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-yas-camera',
	templateUrl: './yas-camera.component.html',
	styleUrls: ['./yas-camera.component.css'],
})
export class YASCameraComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
