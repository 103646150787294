import { Component, OnInit } from '@angular/core';
import { Account } from 'src/app/models/mas-accounts.model';
import { CycleEvent, EventAttendance } from 'src/app/models/mas-cycles.model';
import { MAS_Accounts_Service } from 'src/app/services/mas-accounts.service';
import { MAS_Events_Service } from 'src/app/services/mas-events.service';

@Component({
	selector: 'app-info-events',
	templateUrl: './info-events.component.html',
	styleUrls: ['./info-events.component.css'],
})
export class InfoEventsComponent implements OnInit {
	public cycleEvents: CycleEvent[] = [];
	public eligibleAccounts: Account[] = [];
	public disableButtons: boolean = false;

	constructor(private accounts_Service: MAS_Accounts_Service, private events_Service: MAS_Events_Service) {}

	async ngOnInit() {
		this.cycleEvents = (await this.events_Service.getCurrentEvents()) as CycleEvent[];
		if (window.location.hostname == 'localhost') console.log('cycleEvents', this.cycleEvents);

		this.eligibleAccounts = this.accounts_Service.linkedAccounts;

		if (this.accounts_Service.myAccount?.mas.accountSettings.member === 'true') this.eligibleAccounts.push(this.accounts_Service.myAccount);
	}

	async onButtonClick(cycleEvent: CycleEvent, account: Account) {
		//^ let's not be click happy
		if (this.disableButtons) return;

		this.disableButtons = true;
		const attendanceDetail: EventAttendance = { attended: false, id: account.id, name: `${account.names.givenName} ${account.names.familyName}`, reserved: true };

		const attending = cycleEvent.attendance?.filter(m => m.id === account.id);

		if (!attending || attending.length === 0) {
			(cycleEvent.attendance ??= []).push(attendanceDetail);

			await this.events_Service.setEventById(cycleEvent.id, cycleEvent);
		} else {
			cycleEvent.attendance = cycleEvent.attendance?.filter(m => m.id !== account.id);

			await this.events_Service.setEventById(cycleEvent.id, cycleEvent);
		}
		this.disableButtons = false;
	}

	openUrl(url: string): void {
		window.open(url, '_blank');
	}

	buttonCSS(account: Account, cycleEvent: CycleEvent) {
		const attending = cycleEvent.attendance?.filter(m => m.id === account.id);

		return attending && attending.length > 0 ? 'selected' : 'unselected';
	}
}
