<div class="displayTable">
	<div class="displayTableCell">
		<div class="authBlock">
			<h3>Sign In</h3>
			<div class="formGroup">
				<input
					type="text"
					class="formControl"
					placeholder="Username"
					#userName
					required
					(keydown)="keyDownUsername($event)" />
			</div>
			<div class="formGroup">
				<input
					type="password"
					class="formControl"
					placeholder="Password"
					#userPassword
					required
					(keydown)="keyDownPassword($event, userName.value, userPassword.value)" />
			</div>
			<!-- Calling SignIn Api from AuthService -->
			<div class="formGroup">
				<p-button
					[style]="{ width: '100%' }"
					label="Log in"
					type="button"
					(click)="signIn(userName.value, userPassword.value)">
				</p-button>
			</div>
			<div class="forgotPassword">
				<span routerLink="/forgot-password">Forgot Password?</span>
			</div>
		</div>
		<div class="redirectToLogin">
			<span
				>Don't have an account?<span
					class="redirect"
					routerLink="/register-user">
					Sign Up</span
				></span
			>
			<div>
				<span> v.{{ version }}</span>
			</div>
		</div>
	</div>
</div>
