<div *ngIf="standingsArray && standingsArray.length > 0; else noStandings">
  <div *ngFor="let champion of standingsArray" style="margin-bottom: 1em">
    <p style="font-weight: 900;">{{ champion.name }}</p>
    <div *ngFor="let standing of champion.data" class="grid" style="margin-left: 1em; padding: 0;">
      <div class="col-6" style="padding: 0;">{{ standing.event }}</div>
      <div class="col-6" style="padding: 0; text-align: right;">
        {{ standing.currentStanding == 1 ? '1st' : (standing.currentStanding == 2 ? '2nd' : (standing.currentStanding == 3 ? '3rd' :
        standing.currentStanding + 'th'))}} - Points: {{ standing.points }}
      </div>
    </div>
  </div>
</div>
<div>Standings as of {{lastTournament.tournamentName}} on {{lastTournament.tournamentDate}}.</div>

<ng-template #noStandings>
  <p>No tournament standings available as of {{lastTournament.tournamentName}} on {{lastTournament.tournamentDate}}.</p>
</ng-template>