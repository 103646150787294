import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ViewStandings } from 'src/app/models/utilities.model';

@Component({
	selector: 'app-account-standings',
	templateUrl: './account-standings.component.html',
	styleUrls: ['./account-standings.component.css'],
})
export class AccountStandingsComponent implements OnChanges {
	@Input() standingsArray!: ViewStandings[];
	@Input() lastTournament!: { tournamentDate: string; tournamentName: string };
	constructor() {}
	ngOnChanges() {}
}
