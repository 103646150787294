<div *ngFor="let cycleEvent of cycleEvents" style="margin-bottom: 1em">
     <p-card header="{{cycleEvent.title}}" [style]="{width: '100%'}" styleClass="p-card-shadow">
          <ng-template pTemplate="header">
               <img *ngIf="cycleEvent.image" alt="Card" src="{{cycleEvent.image}}">
          </ng-template>
          <div class="eventdates"> From: {{cycleEvent.startTime | date: 'EEE, MMM d, y h:mm a'}}<br>Until: {{cycleEvent.endTime | date: 'EEE, MMM d, y h:mm a'}} </div>
          <div class="eventdescription" [innerHtml]="cycleEvent.description"></div>
          <div>
               <div *ngIf="cycleEvent.url">
                    <p-button [styleClass]="'unselected'" label="Learn More" (click)="openUrl(cycleEvent.url)"></p-button>
               </div>
               <div *ngIf="cycleEvent.optionReserve" class="flex flex-column align-items-center justify-content-center">
                    <ng-container *ngFor="let account of eligibleAccounts">
                         <p-button [styleClass]="buttonCSS(account, cycleEvent)" label="{{ account.names.givenName }} {{ account.names.familyName }} " (click)="onButtonClick(cycleEvent, account)"
                              [disabled]="disableButtons"></p-button>
                    </ng-container>
               </div>
          </div>
     </p-card>
     <hr style="border-color: #fac62a;">
</div>