<p-progressSpinner *ngIf="spinnerState" class="spinner"></p-progressSpinner>
<div class="displayTable">
  <div class="displayTableCell">
    <div class="authBlock">
      <p-tabMenu [model]="tabMenu" [activeItem]="tabMenu[tabIndex]"></p-tabMenu>
      <app-account-standings *ngIf="tab==='Standings'" [standingsArray]="standingsArray" [lastTournament]="lastTournament"></app-account-standings>
      <app-account-profile *ngIf="tab==='Profile'"></app-account-profile>
      <app-account-settings *ngIf="tab==='Settings'"></app-account-settings>
    </div>
  </div>
</div>