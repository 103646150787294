import { Injectable } from '@angular/core';
import {
	CollectionReference,
	DocumentData,
	DocumentReference,
	Firestore,
	Query,
	collection,
	collectionSnapshots,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	limit,
	orderBy,
	query,
	setDoc,
	where,
} from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { Account } from '../models/mas-accounts.model';
import { CustomFunctions } from '../models/global.model';
import { DisplaySchedule, Notifications, Schedule } from '../models/mas-schedules.model';
import { Firebase_Functions_Service } from './functions.service';
import { MAS_Cycles_Service } from './mas-cycles.service';

@Injectable({
	providedIn: 'root',
})
export class MAS_Schedules_Service {
	public selectedNode!: Account;

	constructor(private afs: Firestore, private cycles_Service: MAS_Cycles_Service, private firebaseFunctions: Firebase_Functions_Service) {}

	async clearSchedules(program: string, testCategory: string | undefined, calendar: Schedule[]) {
		const log: { styleColor: string; msg: string }[] = [];
		const givenDate = CustomFunctions.midnight();

		const searchArray = testCategory ? [program, testCategory] : [program];

		// Create an array of schedule IDs from the Google calendar
		const cal = calendar.map(m => m.id);

		// Get schedules for program after midnight
		const q = query<Schedule, DocumentData>(collection(this.afs, 'mas-schedules') as CollectionReference<Schedule>, where('start.dateTime', '>', givenDate), where('summary', 'in', searchArray));
		const snap = await getDocs(q).then(schedules => schedules.docs.map(m => m.data()));

		// Get any schedule not in the Google calendar
		const snapMapAndFilter = snap.map(m => m.id).filter(id => !cal.includes(id));

		// Remove Schedules that do not have reservations (attendance is empty or not defined)
		const remove = snap.filter(f => snapMapAndFilter.includes(f.id) && (!f.attendance || f.attendance.length === 0));

		remove.forEach(each => {
			this.deleteSchedule(each.id);
			log.push({
				styleColor: 'orange',
				msg: `Removed ${each.summary}, ${each.start.dateTime} - ${each.end.dateTime}`,
			});
		});

		// Keep Schedules with reservations (attendance exists and has members)
		const keep = snap.filter(f => snapMapAndFilter.includes(f.id) && f.attendance && f.attendance.length > 0);

		keep.forEach(each => {
			log.push({
				styleColor: 'red',
				msg: `Schedule does not exist in the Google calendar but could not be removed from MAS because it has reservations: ${each.summary}, ${each.start.dateTime} - ${each.end.dateTime}`,
			});
		});

		return log;
	}

	async deleteSchedule(id: string): Promise<void> {
		const ref = doc(this.afs, 'mas-schedules', id);
		return deleteDoc(ref);
	}

	async getScheduleById(id: string): Promise<Schedule> {
		const docRef = doc(this.afs, 'mas-schedules', id) as DocumentReference<Schedule>;
		return (await getDoc(docRef)).data()!;
	}

	async getSchedulesByProgramAndDateRange(programs: string[], startDate: string, endDate: string) {
		if (programs.length === 0) return [];

		const q = query<Schedule, DocumentData>(
			collection(this.afs, 'mas-schedules') as CollectionReference<Schedule>,
			where('summary', 'in', programs),
			where('start.dateTime', '>', startDate),
			where('start.dateTime', '<', endDate),
			orderBy('start.dateTime')
		);

		return (await getDocs(q)).docs.map(m => m.data());
	}

	async getSchedulesNotClosed(endDate: string, programs: string[]) {
		const q = query<Schedule, DocumentData>(
			collection(this.afs, 'mas-schedules') as CollectionReference<Schedule>,
			where('eventType', '==', 'default'),
			where('summary', 'in', programs),
			where('end.dateTime', '<', endDate)
		);

		const qDocs = await getDocs(q);

		if (!qDocs) return [];

		return qDocs.docs.map(m => m.data());
	}
	async getSchedulesByProgramAsPromise(programName: string) {
		const q = query(collection(this.afs, 'mas-schedules'), where('summary', '==', programName), where('end.dateTime', '>', CustomFunctions.localClientTime()));
		const qDocs = await getDocs(q);
		const qData = qDocs.docs.map(m => m.data());

		const schedules = qData.map(m => {
			m['startDateTime'] = CustomFunctions.dateToFriendlyDate(m['start'].dateTime as unknown as Date);
			return m;
		});

		return schedules;
	}
	getSchedulesByCycleAndProgram(cycle: string, program: string) {
		return collectionSnapshots<Schedule>(
			query<Schedule, DocumentData>(
				collection(this.afs, 'mas-schedules') as CollectionReference<Schedule>,
				where('cycleName', '==', cycle),
				where('summary', '==', program),
				orderBy('start.dateTime')
			)
		).pipe(
			map(changes => {
				return changes.map(a => {
					const data = a.data();
					data.id = a.id;
					return data;
				});
			})
		);
	}

	async getSchedulesAsPromise(ignorePast: boolean = false): Promise<Schedule[]> {
		try {
			// Define the base collection reference
			const collectionRef = collection(this.afs, 'mas-schedules') as CollectionReference<Schedule>;

			// Conditionally add the where clause based on ignorePast
			const q: Query<Schedule> = ignorePast
				? query<Schedule, DocumentData>(collectionRef, where('end.dateTime', '>', CustomFunctions.localClientTime()))
				: query<Schedule, DocumentData>(collectionRef);

			// Execute the query and return the results
			const querySnapshot = await getDocs(q);
			return querySnapshot.docs.map(doc => doc.data());
		} catch (error) {
			console.error('Failed to get schedules:', error);
			throw error;
		}
	}

	async getSchedulesWithMembersAttendedAsPromise(id: string) {
		const q = query<Schedule, DocumentData>(collection(this.afs, 'mas-schedules') as CollectionReference<Schedule>, where('id', '>', id));
		return await getDocs(q).then(data => data.docs.map(m => m.data()));
	}
	getSchedulesByDateRangeLimit3(startDate: string, endDate: string) {
		return collectionSnapshots<DisplaySchedule>(
			query<DisplaySchedule, DocumentData>(
				collection(this.afs, 'mas-schedules') as CollectionReference<DisplaySchedule>,
				where('end.dateTime', '>', startDate),
				where('end.dateTime', '<', endDate),
				orderBy('end.dateTime'),
				limit(3)
			)
		).pipe(
			map(changes => {
				return changes.map(a => {
					const data = a.data();
					data.startTime = CustomFunctions.timeToDateString12(data.start.dateTime as unknown as Date);
					data.endTime = CustomFunctions.timeToDateString12(data.end.dateTime as unknown as Date);
					data.id = a.id;
					return data;
				});
			})
		);
	}

	getSchedulesByDateRangeLimit1(startDate: string, endDate: string) {
		return collectionSnapshots<DisplaySchedule>(
			query<DisplaySchedule, DocumentData>(
				collection(this.afs, 'mas-schedules') as CollectionReference<DisplaySchedule>,
				where('end.dateTime', '>', startDate),
				where('end.dateTime', '<', endDate),
				where('summary', '==', 'YAS'),
				orderBy('end.dateTime'),
				limit(1)
			)
		).pipe(
			map(changes => {
				return changes.map(a => {
					const data = a.data();
					data.startTime = CustomFunctions.timeToDateString12(data.start.dateTime as unknown as Date);
					data.endTime = CustomFunctions.timeToDateString12(data.end.dateTime as unknown as Date);
					data.id = a.id;
					return data;
				});
			})
		);
	}
	getSchedulesByProgramAsObservable(program: string) {
		const d = new Date();
		const givenDate = CustomFunctions.dateToDateString(d);

		return collectionSnapshots<Schedule>(
			query<Schedule, DocumentData>(
				collection(this.afs, 'mas-schedules') as CollectionReference<Schedule>,
				where('summary', '==', program),
				where('start.dateTime', '>=', givenDate),
				orderBy('start.dateTime')
			)
		).pipe(
			map(changes => {
				return changes.map(a => {
					const data = a.data();
					data.id = a.id;
					return data;
				});
			})
		);
	}
	async getSchedulesByProgramForChart(program: string) {
		const d = new Date();
		const givenDate = CustomFunctions.dateToDateString(d);

		const q = query<Schedule, DocumentData>(
			collection(this.afs, 'mas-schedules') as CollectionReference<Schedule>,
			where('summary', '==', program),
			where('start.dateTime', '<', givenDate),
			where('start.dateTime', '>', '2022'),
			orderBy('start.dateTime')
		);

		const qDocs = await getDocs(q);

		if (!qDocs) return [];

		return qDocs.docs.map(m => m.data());
	}
	getSchedulesByReference(fld: string, operator: any, condition: any) {
		return collectionSnapshots<any>(query<any, DocumentData>(collection(this.afs, 'mas-schedules') as CollectionReference<any>, where(fld, operator, condition))).pipe(
			map(changes => {
				return changes.map(c => {
					const data = c.data() as any;
					data.id = c.id;
					return data;
				});
			})
		);
	}
	async getSchedulesByTestCategories(testCategories: string[]) {
		if (testCategories.length === 0) return [];

		const q = query<Schedule, DocumentData>(
			collection(this.afs, 'mas-schedules') as CollectionReference<Schedule>,
			where('summary', 'in', testCategories),
			where('end.dateTime', '>', CustomFunctions.localClientTime())
		);
		const qDocs = await getDocs(q);
		const qData = qDocs.docs.map(m => m.data());

		const schedules = qData.map((m: any) => {
			m.startDateTime = CustomFunctions.dateToFriendlyDate(m.start.dateTime as unknown as Date);
			return m;
		});

		return <Schedule[]>schedules;
	}
	getSchedulesForTesting(cycle: string, programs: string[]) {
		const q = query<Schedule, DocumentData>(collection(this.afs, 'mas-schedules') as CollectionReference<Schedule>, where('cycleName', '==', cycle), where('summary', 'in', programs));
		return getDocs(q);
	}
	async getSchedulesForYAS() {
		const q = query<Schedule, DocumentData>(collection(this.afs, 'mas-schedules') as CollectionReference<Schedule>, where('summary', '==', 'YAS'), where('end.dateTime', '>', '2023-06-01'));
		return await getDocs(q).then(document => document.docs.map(m => m.data()));
	}
	async getSchedulesToUpdate(program: string) {
		const d = new Date();
		const givenDate = CustomFunctions.dateToDateString(d);

		const q = query<Schedule, DocumentData>(collection(this.afs, 'mas-schedules') as CollectionReference<Schedule>, where('summary', '==', program), where('start.dateTime', '>=', givenDate));
		const qDocs = await getDocs(q);

		if (!qDocs) return [];

		return qDocs.docs.map(m => m.data());
	}

	getSchedulesToUpdateCycles() {
		this.getSchedulesAsPromise().then(schedules => {
			let results: any[] = [];
			schedules.forEach(schedule => {
				results.push(schedule);
			});
			this.updateSchedulesCycle(results);
		});
	}
	async setScheduleById(id: string, payload: Schedule, mode: boolean = true) {
		const schedule = doc(this.afs, 'mas-schedules', id);
		return await setDoc(schedule, payload, { merge: mode });
	}

	async findExistingSchedule(each: Schedule): Promise<boolean> {
		const q = query<Schedule, DocumentData>(
			collection(this.afs, 'mas-schedules') as CollectionReference<Schedule>,
			where('summary', '==', each.summary),
			where('start.dateTime', '==', each.start.dateTime),
			where('end.dateTime', '==', each.end.dateTime)
		);
		const count = await getDocs(q).then(docs => docs.size);
		return count > 0 ? true : false;
	}

	updateSchedulesCycle(schedules: any[]) {
		this.cycles_Service.getCurrentCyclesAsPromise().then(cycles => {
			cycles.forEach(cycle => {
				schedules.forEach(schedule => {
					const result = cycle;

					const shortStartDate = new Date(schedule.start.dateTime);

					const shortStartDateAsString = CustomFunctions.dateToDateString(shortStartDate);

					if (shortStartDateAsString >= result.startDate && shortStartDateAsString <= result.endDate) {
						schedule.cycleName = result.cycleName;
						this.setScheduleById(schedule.id, schedule);
					}
				});
			});
		});
	}
	async getSchedulesByPropertiesAsPromise(searchArray: Notifications[]) {
		const q = query<Schedule, DocumentData>(collection(this.afs, 'mas-schedules') as CollectionReference<Schedule>, where('notifications', 'array-contains-any', searchArray));
		return (await getDocs(q)).docs.map(m => m.data());
	}
	async getSchedulesWithNotifications() {
		const today = new Date();
		today.setHours(today.getHours() - 4);

		const tomorrow = new Date();
		tomorrow.setDate(tomorrow.getDate() + 2);

		const q = query<Schedule, DocumentData>(
			collection(this.afs, 'mas-schedules') as CollectionReference<Schedule>,
			where('start.dateTime', '>', today.toISOString().slice(0, 10)),
			where('start.dateTime', '<', tomorrow.toISOString().slice(0, 10))
		);
		return (await getDocs(q)).docs.map(m => m.data());
	}
}
