<div class="displayTable">
	<div class="displayTableCell">
		<div class="authBlock">
			<h3>Reset Password</h3>
			<p class="text-center">Please enter your email address to request a password reset.</p>
			<div class="formGroup">
				<input
					type="email"
					class="formControl"
					placeholder="Email Address"
					#passwordResetEmail
					required />
			</div>
			<!-- Calling ForgotPassword from AuthService Api -->
			<div class="formGroup">
				<p-button
					[style]="{ width: '100%' }"
					label="Reset Password"
					type="button"
					(click)="identityService.forgotPassword(passwordResetEmail.value)">
				</p-button>
			</div>
		</div>
		<div class="redirectToLogin">
			<span
				>Go back to ?
				<span
					class="redirect"
					routerLink="/sign-in"
					>Log In</span
				></span
			>
		</div>
	</div>
</div>
