import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { GoogleIdentityService } from './services/identity.service';
import { MAS_Accounts_Service } from './services/mas-accounts.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
	public items: any;
	public dockItems!: MenuItem[];

	constructor(public identityService: GoogleIdentityService, public accounts_Service: MAS_Accounts_Service, private router: Router) {}

	ngOnInit(): void {
		window.scroll({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});

		this.getDockItems();

		this.identityService.getUser.subscribe(async user => {
			if (user?.email) {
				if (!this.accounts_Service.myAccount) {
					await this.accounts_Service.getAccountsByEmail(user?.email);
					this.router.navigate(['account']);
					this.identityService.setDock = true;
				} else {
					this.router.navigate(['account']);
					this.identityService.setDock = true;
				}
			} else {
				this.identityService.setDock = false;
				this.router.navigate(['sign-in']);
			}
		});
	}

	logout() {
		this.identityService.signOut();
	}

	getDockItems() {
		this.dockItems = [
			{
				icon: '../assets/account.svg',
				label: 'Account',
				routerLink: ['/account'],
				title: 'Account Profile Page',
			},
			{
				icon: '../assets/programs.svg',
				label: 'Programs',
				routerLink: ['/programs'],
				title: 'Manage Programs',
			},
			{
				icon: '../assets/info.svg',
				label: 'Info',
				routerLink: ['/info'],
				title: 'General Information',
			},
			{
				command: () => {
					this.logout();
				},
				icon: '../assets/logout.svg',
				label: 'Logout',
				title: 'Logout',
			},
		];
	}
}
