import { Component, OnInit } from '@angular/core';
import { MAS_Accounts_Service } from 'src/app/services/mas-accounts.service';

@Component({
	selector: 'app-account-profile',
	templateUrl: './account-profile.component.html',
	styleUrls: ['./account-profile.component.css'],
})
export class AccountProfileComponent implements OnInit {
	constructor(public accounts_Service: MAS_Accounts_Service) {}

	ngOnInit() {}
}
