import { Injectable } from '@angular/core';
import { addDoc, collection, CollectionReference, deleteDoc, doc, DocumentData, Firestore, getDocs, orderBy, query, setDoc, where } from '@angular/fire/firestore';
import { Contribution } from '../models/pie.model';
import { PIE_Partners_Service } from './pie-partners.service';

@Injectable({
	providedIn: 'root',
})
export class PIE_Contributions_Service {
	constructor(private afs: Firestore, private partner_Service: PIE_Partners_Service) {}

	async addContribution(payload: Contribution) {
		const ref = collection(this.afs, 'pie-contributions');
		const doc = await addDoc(ref, payload);
		return doc.id;
	}
	async getContributions() {
		const q = query(collection(this.afs, 'pie-contributions'), where('name', '==', this.partner_Service.partner.name));
		return await getDocs(q).then(document => document);
	}
	async getImpactBySchool(school: string) {
		const q = query<Contribution, DocumentData>(
			collection(this.afs, 'pie-contributions') as CollectionReference<Contribution>,
			where('name', '==', school),
			orderBy('timeIn')
		);
		return await getDocs(q);
	}

	async setContribution(id: string, payload: Contribution) {
		const ref = doc(this.afs, 'pie-contributions', id);
		setDoc(ref, payload, { merge: true });
		return id;
	}
	deleteContribution(id: string) {
		const ref = doc(this.afs, 'pie-contributions', id);
		deleteDoc(ref);
	}

	async getImpact() {
		const q = query<Contribution, DocumentData>(collection(this.afs, 'pie-contributions') as CollectionReference<Contribution>);
		return (await getDocs(q)).docs.map(m => m.data());
	}
}
