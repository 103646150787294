<h3 *ngIf="accounts_Service.myAccount"> {{ accounts_Service.myAccount.names.givenName }} {{ accounts_Service.myAccount.names.familyName }} </h3>
<p-divider></p-divider>
<p *ngIf="accounts_Service.myAccount?.emailAddresses?.value">
  <i class="pi pi-envelope mr-3"></i> {{ accounts_Service.myAccount.emailAddresses?.value }}
</p>
<p-divider></p-divider>
<p *ngIf="accounts_Service.myAccount?.phoneNumbers?.value">
  <i class="pi pi-id-card mr-3"></i> {{ accounts_Service.myAccount.phoneNumbers?.value }}
</p>
<p-divider></p-divider>
<p *ngIf="accounts_Service.myAccount?.addresses?.streetAddress">
  <i class="pi pi-map-marker" style="margin-right: 3px"></i>{{ accounts_Service.myAccount.addresses?.streetAddress }}<br />
  <i style="margin-right: 19px"> </i> {{ accounts_Service.myAccount.addresses?.city }} {{ accounts_Service.myAccount.addresses?.region }} {{ accounts_Service.myAccount.addresses?.postalCode }}
</p>
<p-divider></p-divider>