import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { ViewStandings } from 'src/app/models/utilities.model';
import { GoogleIdentityService } from 'src/app/services/identity.service';
import { MAS_Accounts_Service } from 'src/app/services/mas-accounts.service';

@Component({
	selector: 'app-account',
	templateUrl: './account.component.html',
	styleUrls: ['./account.component.css'],
})
export class AccountComponent implements OnInit {
	public standingsArray: ViewStandings[] = [];
	public lastTournament!: { tournamentDate: string; tournamentName: string };
	public spinnerState = true;
	public tabMenu: MenuItem[];
	public tabIndex: number = 0;
	public tab: string = 'Profile';

	constructor(public identityService: GoogleIdentityService, private accounts_Service: MAS_Accounts_Service, private router: Router) {
		this.tabMenu = [
			{
				label: 'Profile',
				command: () => {
					this.selectedTab('Profile');
				},
			},
			{
				label: 'Standings',
				command: () => {
					this.selectedTab('Standings');
				},
			},
			{
				label: 'Settings',
				command: () => {
					this.selectedTab('Settings');
				},
			},
		];
	}

	async ngOnInit(): Promise<void> {
		try {
			await this.loadStandings();

			if (!this.accounts_Service.myAccount) {
				this.router.navigate(['/']);
			}

			this.spinnerState = false;
		} catch (error) {
			console.error('Error initializing:', error);
		}

		this.lastTournament = await this.accounts_Service.getLastTournament();
		console.log(this.lastTournament);
	}

	selectedTab(tab: string): void {
		this.tab = tab;
	}

	async loadStandings(): Promise<void> {
		const { myAccount, linkedAccounts } = this.accounts_Service;

		if (myAccount?.mas?.accountSettings?.member === 'true') {
			const standingsData = await this.accounts_Service.getAccountStandings(myAccount.id);
			if (standingsData.length > 0) {
				this.addStandingsElement(myAccount, standingsData);
			}
		}

		if (linkedAccounts.length > 0) {
			const linkedAccountPromises = linkedAccounts.map(async link => {
				const linkAccountStandings = await this.accounts_Service.getAccountStandings(link.id);
				if (linkAccountStandings.length > 0) {
					this.addStandingsElement(link, linkAccountStandings);
				}
			});

			await Promise.all(linkedAccountPromises);
		}
	}

	addStandingsElement(account: any, standingsData: any[]): void {
		const element = {
			name: `${account.names.givenName} ${account.names.familyName} `,
			data: standingsData,
		};
		this.standingsArray.push(element);
		this.setDefaultTabToStandings();
	}

	setDefaultTabToStandings(): void {
		if (this.standingsArray.length > 0) {
			this.selectedTab('Standings');
			this.tabIndex = 1;
		}
	}
}
