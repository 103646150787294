import { Injectable, NgZone } from '@angular/core';
import {
	Auth,
	authState,
	ConfirmationResult,
	createUserWithEmailAndPassword,
	getAuth,
	GoogleAuthProvider,
	PhoneAuthProvider,
	RecaptchaVerifier,
	sendEmailVerification,
	sendPasswordResetEmail,
	signInWithCredential,
	signInWithEmailAndPassword,
	signInWithPhoneNumber,
	signInWithPopup,
	User,
} from '@angular/fire/auth';
import { collection, CollectionReference, collectionSnapshots, DocumentData, Firestore, query, where } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { gapiSettings } from 'src/environments/gapisettings';
import { Account, gapi } from '../models/mas-accounts.model';

@Injectable({
	providedIn: 'root',
})
export class GoogleIdentityService {
	private user$: Observable<User | null>;
	private showDock = true;
	private hasAuthUser: string | null = null;
	private idToken: string | null = null;
	private accountGAPI = new BehaviorSubject<gapi | undefined>(undefined);
	public token$: Observable<gapi | undefined> = this.accountGAPI.asObservable();

	constructor(public auth: Auth, private router: Router, public ngZone: NgZone, public firestore: Firestore) {
		this.user$ = authState(this.auth as any);

		this.user$.subscribe(async user => {
			if (user?.email) this.hasAuthUser = user.email;
			const id = await user?.getIdToken();
			if (id) this.idToken = id;
		});
	}

	get isSignedIn(): string | null {
		return this.hasAuthUser;
	}

	get getUser() {
		return this.user$;
	}

	get getUserIdToken() {
		return this.idToken;
	}

	set setDock(state: boolean) {
		this.showDock = state;
	}

	get getDock() {
		return this.showDock;
	}

	initToken(email: string) {
		collectionSnapshots<Account>(query<Account, DocumentData>(collection(this.firestore, 'mas-accounts') as CollectionReference<Account>, where('emailAddresses.value', '==', email)))
			.pipe(
				map(changes => {
					return changes.map(a => {
						const data = a.data();
						if (data.mas?.gapi?.masAccountId) data.mas.gapi!.masAccountId = a.id;
						return data.mas.gapi;
					});
				})
			)
			.subscribe(token => this.accountGAPI.next(token.pop()));
	}

	/*
		Sign in with email/password
	*/
	async signInWithEmail(email: string, password: string) {
		return await signInWithEmailAndPassword(this.auth, email, password);
	}

	/*
		Sign up with email/password
	*/
	async signUpWithEmail(email: string, password: string) {
		return await createUserWithEmailAndPassword(this.auth, email, password)
			.then(result => {
				this.sendVerificationMail();
				return GoogleAuthProvider.credentialFromResult(result);
			})
			.catch(error => {
				console.error('Error signing up with email and password:', error);
				throw error;
			});
	}

	/*
		Send email verfificaiton when new user sign up
	*/
	sendVerificationMail() {
		if (this.auth.currentUser)
			sendEmailVerification(this.auth.currentUser).then(() => {
				this.router.navigate(['verify-email-address']);
			});
	}

	/*
		Reset Forgot password
	*/
	async forgotPassword(passwordResetEmail: string) {
		return await sendPasswordResetEmail(this.auth, passwordResetEmail)
			.then(() => {
				window.alert('Password reset email sent, check your inbox.');
			})
			.catch(error => {
				window.alert(error);
			});
	}

	/*
		Auth logic to run auth providers
	*/
	async signInPopup() {
		const provider = new GoogleAuthProvider();
		provider.addScope(gapiSettings.Cfg.scope);

		const auth = getAuth();

		signInWithPopup(auth, provider);
	}

	async signOut() {
		return await this.auth.signOut();
	}

	getRecaptcha() {
		return new RecaptchaVerifier(this.auth, 'recaptcha-container', {
			size: 'invisible',
			callback: (response: any) => response,
			'expired-callback': () => 'expired',
		});
	}

	async signInWithPhone(appVerifier: any, phoneNumber: string) {
		return await signInWithPhoneNumber(this.auth, phoneNumber, appVerifier);
	}

	async usePhoneCredentials(confirmationResult: ConfirmationResult, verificationCode: string) {
		const credential = PhoneAuthProvider.credential(confirmationResult.verificationId, verificationCode);

		return await signInWithCredential(this.auth, credential)
			.then(result => {
				console.log(result);
			})
			.catch(error => {
				window.alert(error.message);
			});
	}

	getAuthInstance() {
		return getAuth();
	}
}
