import { Component, OnInit } from '@angular/core';
import { Notifications } from 'src/app/models/mas-schedules.model';
import { MAS_Accounts_Service } from 'src/app/services/mas-accounts.service';
import { MAS_Schedules_Service } from 'src/app/services/mas-schedules.service';

@Component({
	selector: 'app-account-settings',
	templateUrl: './account-settings.component.html',
	styleUrls: ['./account-settings.component.css'],
})
export class AccountSettingsComponent implements OnInit {
	public emailOptions: [{ label: 'On'; value: true }, { label: 'Off'; value: false }] = [
		{ label: 'On', value: true },
		{ label: 'Off', value: false },
	];
	public selectButtonEmail: boolean = false;
	public textOptions: [{ label: 'On'; value: true }, { label: 'Off'; value: false }] = [
		{ label: 'On', value: true },
		{ label: 'Off', value: false },
	];
	public selectButtonText: boolean = false;
	constructor(public accounts_Service: MAS_Accounts_Service, private schedule_Service: MAS_Schedules_Service) {}
	ngOnInit(): void {}

	ngAfterContentChecked(): void {
		if (this.accounts_Service.myAccount.mas.accountSettings?.enableSMS) this.selectButtonText = this.accounts_Service.myAccount.mas.accountSettings?.enableSMS;
		if (this.accounts_Service.myAccount.mas.accountSettings?.enableEmailReminders)
			this.selectButtonEmail = this.accounts_Service.myAccount.mas.accountSettings?.enableEmailReminders;
	}

	enableSMSReminders(event: any) {
		this.accounts_Service.handleSMSReminders(event.option.value);
		this.updateNotifications();
	}

	enableEmailReminders(event: any) {
		this.accounts_Service.handleEmailReminders(event.option.value);
		this.updateNotifications();
	}

	async updateNotifications() {
		const searchArray: Notifications[] = [];

		searchArray.push({
			name: `${this.accounts_Service.myAccount.names.givenName} ${this.accounts_Service.myAccount.names.familyName}`,
			id: this.accounts_Service.myAccount.id,
		});

		searchArray.push({
			name: `${this.accounts_Service.myAccount.names.givenName} ${this.accounts_Service.myAccount.names.familyName}`,
			id: this.accounts_Service.myAccount.id,
			email: this.accounts_Service.myAccount.emailAddresses?.value,
		});

		searchArray.push({
			name: `${this.accounts_Service.myAccount.names.givenName} ${this.accounts_Service.myAccount.names.familyName}`,
			id: this.accounts_Service.myAccount.id,
			phone: this.accounts_Service.myAccount.phoneNumbers?.value,
		});

		searchArray.push({
			name: `${this.accounts_Service.myAccount.names.givenName} ${this.accounts_Service.myAccount.names.familyName}`,
			id: this.accounts_Service.myAccount.id,
			email: this.accounts_Service.myAccount.emailAddresses?.value,
			phone: this.accounts_Service.myAccount.phoneNumbers?.value,
		});

		const schedules = await this.schedule_Service.getSchedulesByPropertiesAsPromise(searchArray);

		const notification: Notifications = {
			id: this.accounts_Service.myAccount.id,
			name: `${this.accounts_Service.myAccount.names.givenName} ${this.accounts_Service.myAccount.names.familyName}`,
		};

		if (this.accounts_Service.myAccount.mas.accountSettings.enableEmailReminders) notification.email = this.accounts_Service.myAccount.emailAddresses?.value;
		if (this.accounts_Service.myAccount.mas.accountSettings.enableSMS) notification.phone = this.accounts_Service.myAccount.phoneNumbers?.value;

		schedules.forEach(schedule => {
			schedule.notifications = schedule.notifications?.map(m => {
				if (m.id === this.accounts_Service.myAccount.id) m = notification;
				return m;
			});
			this.schedule_Service.setScheduleById(schedule.id, schedule);
		});
	}
}
