<p-progressSpinner *ngIf="spinnerState" class="spinner"></p-progressSpinner>
<p-table [value]="accounts" dataKey="id" styleClass="p-datatable-gridlines p-datatable-striped">
	<ng-template pTemplate="body" let-account let-expanded="expanded">
		<tr>
			<td style="text-align: left" class="flex">
				<div style="width: 90%">{{ account.names.givenName }} {{ account.names.familyName }}</div>
				<div>
					<button type="button" pButton pRipple [pRowToggler]="account" class="p-button-text p-button-rounded p-button-plain"
						[icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" style="margin: 0px"></button>
				</div>
			</td>
		</tr>
	</ng-template>
	<ng-template pTemplate="rowexpansion" let-account let-accountIndex="rowIndex">
		<tr>
			<td>
				<p-table [value]="account.tests" dataKey="buttonLabel" styleClass="p-datatable-gridlines p-datatable-striped" [scrollable]="false">
					<ng-template pTemplate="body" let-test let-testIndex="rowIndex">
		<tr>
			<td class="flex flex-column align-items-center justify-content-center">
				<div>{{ test.category }}</div>
				<ng-container *ngFor="let button of test.schedules; let i = index">
					<p-button [styleClass]="button.details?.css" label="{{ button.startDateTime }}" [icon]="button.details?.icon"
						badge="{{ button.details?.testingLimits - button.attendance?.length }}" (click)="onClick(account, button )"
						[disabled]="button.details?.disable || spinnerState"></p-button>
				</ng-container>
			</td>
		</tr>
	</ng-template>
</p-table>
</td>
</tr>
</ng-template>
</p-table>