import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-info-activity',
	templateUrl: './info-activity.component.html',
	styleUrls: ['./info-activity.component.css'],
})
export class InfoActivityComponent implements OnInit {
	constructor() {}
	ngOnInit(): void {}
}
