/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, OnInit } from '@angular/core';
import { Schedule } from 'src/app/models/mas-schedules.model';
import { MAS_Accounts_Service } from 'src/app/services/mas-accounts.service';
import { MAS_Programs_Service } from 'src/app/services/mas-programs.service';
import { MAS_Schedules_Service } from 'src/app/services/mas-schedules.service';

@Component({
	selector: 'app-yas-invoice',
	templateUrl: './yas-invoice.component.html',
	styleUrls: ['./yas-invoice.component.css'],
})
export class YASInvoiceComponent implements OnInit {
	public invoices!: any[];
	public dailyCost: number = 0;
	constructor(private schedules_Service: MAS_Schedules_Service, private accounts_Service: MAS_Accounts_Service, private programsService: MAS_Programs_Service) {}

	ngOnInit() {
		this.getYASInvoices();
	}

	async getYASInvoices() {
		const YAS = await this.programsService.getProgramByName('YAS');
		const YASID = YAS?.id ?? '';
		this.dailyCost = YAS?.dailyCost ? YAS.dailyCost : 0;

		const YASAccounts = this.accounts_Service.linkedAccounts.filter(f => f.memberships?.includes(YASID));

		const promisedInvoices = YASAccounts.map(async m => {
			//^ setup object structure
			const invoiceData: { member: string; name: string; periods: { key: string; value: any }[] } = {
				member: m.id,
				name: `${m.names.givenName} ${m.names.familyName}`,
				periods: [],
			};

			//^ get schedules to aggregate
			const schedules = await this.schedules_Service.getSchedulesForYAS();

			const attendance = schedules.filter(schedule => {
				const scheduleAttendance = schedule.attendance?.map(m => m.id);

				const test = scheduleAttendance?.includes(m.id) ? true : false;

				return test;
			});

			//^ get attendance data as invoice entries
			const invoiceEntries = attendance.map(m => {
				return { period: m.start.dateTime.substring(0, 7), count: 1 };
			});

			//^ reducer utility
			const reducer = (map: any, val: any) => {
				if (map[val] == null) {
					map[val] = 1;
				} else {
					++map[val];
				}
				return map;
			};

			const invoices = invoiceEntries.map(value => value.period).reduce(reducer, {});

			invoiceData.periods = await this.sortObject(invoices);

			return invoiceData;
		});

		this.invoices = await Promise.all(promisedInvoices);
	}

	async sortObject(obj: any) {
		var arr = [];
		for (var prop in obj) {
			if (obj.hasOwnProperty(prop)) {
				arr.push({
					key: prop,
					value: obj[prop],
				});
			}
		}

		arr.sort((a, b) => {
			if (a.key > b.key) return -1;
			if (a.key < b.key) return 1;
			return 0;
		});

		return arr;
	}
}
