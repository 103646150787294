import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleIdentityService } from 'src/app/services/identity.service';
import { MAS_Accounts_Service } from 'src/app/services/mas-accounts.service';

@Component({
	selector: 'app-sign-in',
	templateUrl: './sign-in.component.html',
	styleUrls: ['./sign-in.component.css'],
})
export class SignInComponent {
	public version: string = '3.0.2';
	elm!: ElementRef<HTMLInputElement>;

	constructor(public identityService: GoogleIdentityService, public router: Router, public accounts_Service: MAS_Accounts_Service) {}

	async signIn(email: string, password: string) {
		try {
			email = email.toLowerCase();
			await this.identityService.signInWithEmail(email, password);
			try {
				await this.accounts_Service.getAccountsByEmail(email);
			} catch (accountsError) {
				console.error(`Failed to fetch accounts for email ${email}`, accountsError);
				window.alert(`Failed to fetch accounts for email ${email}`);
			}
		} catch (signInError) {
			console.error(`Sign in failed for email ${email}`, signInError);
			window.alert(`Sign in failed for email ${email}`);
		}
	}

	keyDownUsername(event: { keyCode: number }) {
		if (event.keyCode === 13) {
			this.elm.nativeElement.focus();
		}
	}

	keyDownPassword(event: { keyCode: number }, email: string, password: string) {
		if (event.keyCode === 13) {
			this.signIn(email, password);
		}
	}
}
