import { Component, OnInit } from '@angular/core';
import { GoogleIdentityService } from 'src/app/services/identity.service';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent implements OnInit {
	constructor(public identityService: GoogleIdentityService) {}

	ngOnInit() {}
}
