import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountComponent } from '../components/account/account.component';
import { ForgotPasswordComponent } from '../components/forgot-password/forgot-password.component';
import { InfoComponent } from '../components/info/info.component';
import { ProgramsComponent } from '../components/programs/programs.component';
import { SignInComponent } from '../components/sign-in/sign-in.component';
import { SignUpComponent } from '../components/sign-up/sign-up.component';
import { AuthGuard } from '../guards/auth.guard';

const routes: Routes = [
	{ path: '', redirectTo: '/account', pathMatch: 'full' },
	{ path: 'sign-in', component: SignInComponent },
	{ path: 'register-user', component: SignUpComponent },
	{
		path: 'account',
		component: AccountComponent,
		canActivate: [AuthGuard],
	},
	{
		path: 'programs',
		component: ProgramsComponent,
		canActivate: [AuthGuard],
	},
	{
		path: 'info',
		component: InfoComponent,
		canActivate: [AuthGuard],
	},

	{ path: 'forgot-password', component: ForgotPasswordComponent },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
