import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { connectAuthEmulator, getAuth, provideAuth } from '@angular/fire/auth';
import { Firestore, connectFirestoreEmulator, getFirestore, initializeFirestore, provideFirestore } from '@angular/fire/firestore';
import { Functions, connectFunctionsEmulator, getFunctions, provideFunctions } from '@angular/fire/functions';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { firebase } from '../../environments/environment';
import { AppComponent } from '../app.component';
import { AccountComponent } from '../components/account/account.component';
import { AccountProfileComponent } from '../components/account/profile/account-profile.component';
import { AccountSettingsComponent } from '../components/account/settings/account-settings.component';
import { AccountStandingsComponent } from '../components/account/standings/account-standings.component';
import { ForgotPasswordComponent } from '../components/forgot-password/forgot-password.component';
import { InfoActivityComponent } from '../components/info/activity/info-activity.component';
import { InfoEventsComponent } from '../components/info/events/info-events.component';
import { InfoComponent } from '../components/info/info.component';
import { InfoLifeSkillsComponent } from '../components/info/lifeskills/info-lifeskills.component';
import { InfoPieComponent } from '../components/info/pie/info-pie.component';
import { ProgramsClassesComponent } from '../components/programs/classes/programs-classes.component';
import { ProgramsComponent } from '../components/programs/programs.component';
import { ProgramsTestingComponent } from '../components/programs/testing/programs-testing.component';
import { ProgramsYASComponent } from '../components/programs/yas/programs-yas.component';
import { YASCameraComponent } from '../components/programs/yas/yas-camera/yas-camera.component';
import { YASInvoiceComponent } from '../components/programs/yas/yas-invoice/yas-invoice.component';
import { SignInComponent } from '../components/sign-in/sign-in.component';
import { SignUpComponent } from '../components/sign-up/sign-up.component';
import { AuthGuard } from '../guards/auth.guard';
import { NGPrimeModule } from '../modules/ngprime.module';
import { AccountResolverService } from '../resolvers/account.resolver';
import { AppRoutingModule } from '../routing/app.routing';
import { GoogleIdentityService } from '../services/identity.service';

const isLocalhost = window.location.hostname === 'localhost';

@NgModule({
	declarations: [
		AccountComponent,
		InfoPieComponent,
		AccountProfileComponent,
		AccountSettingsComponent,
		AccountStandingsComponent,
		AppComponent,
		ForgotPasswordComponent,
		InfoActivityComponent,
		InfoComponent,
		InfoEventsComponent,
		InfoLifeSkillsComponent,
		ProgramsClassesComponent,
		ProgramsComponent,
		ProgramsTestingComponent,
		ProgramsYASComponent,
		SignInComponent,
		SignUpComponent,
		YASCameraComponent,
		YASInvoiceComponent,
	],
	imports: [
		AppRoutingModule,
		BrowserAnimationsModule,
		BrowserAnimationsModule,
		BrowserModule,
		BrowserModule,
		FormsModule,
		FormsModule,
		HttpClientModule,
		NGPrimeModule,
		ReactiveFormsModule,
		provideFirebaseApp(() => initializeApp(firebase.Cfg)),
		provideAuth(() => {
			const auth = getAuth();
			if (isLocalhost) {
				connectAuthEmulator(auth, 'http://localhost:9099', {
					disableWarnings: true,
				});
			}
			return auth;
		}),
		provideFirestore(() => {
			let firestore: Firestore;
			if (isLocalhost) {
				firestore = initializeFirestore(getApp(), {
					experimentalForceLongPolling: true,
				});
				connectFirestoreEmulator(firestore, 'localhost', 8080);
			} else {
				firestore = getFirestore();
			}
			return firestore;
		}),
		provideFunctions(() => {
			let functions: Functions;
			if (isLocalhost) {
				functions = getFunctions();
				connectFunctionsEmulator(functions, 'localhost', 5001);
			} else {
				functions = getFunctions();
			}
			return functions;
		}),
	],
	providers: [GoogleIdentityService, AuthGuard, AccountResolverService],
	bootstrap: [AppComponent],
})
export class AppModule {}
