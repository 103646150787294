import { Injectable } from '@angular/core';
import {
	addDoc,
	collection,
	CollectionReference,
	collectionSnapshots,
	deleteDoc,
	doc,
	DocumentData,
	DocumentReference,
	Firestore,
	getDoc,
	getDocs,
	limit,
	orderBy,
	query,
	setDoc,
	where,
} from '@angular/fire/firestore';
import { map, Observable } from 'rxjs';
import { Ledger, Program, ProgramAndSchedules, ProgramMember } from '../models/mas-programs.model';
import { MAS_Schedules_Service } from './mas-schedules.service';
import { Firebase_Functions_Service } from './functions.service';

@Injectable({
	providedIn: 'root',
})
export class MAS_Programs_Service {
	constructor(private afs: Firestore, private schedule_Service: MAS_Schedules_Service, private firebaseFunctions: Firebase_Functions_Service) {}

	async checkYASMembership(id: string) {
		const q = query(collection(this.afs, 'mas-programs'), where('name', '==', 'YAS'));

		const qDocs = await getDocs(q);

		if (!qDocs) return false;

		const yasProgram = <Program>qDocs.docs.pop()?.data();

		if (!yasProgram) return false;

		const yasMembership = yasProgram.memberships?.map(m => m.memberId);

		return yasMembership?.includes(id);
	}

	async getProgramById(programId: string): Promise<Program | undefined> {
		const docRef = doc(this.afs, 'mas-programs', programId);

		const qDoc = await getDoc(docRef);

		if (!qDoc) return;

		return qDoc.data() as Program;
	}

	async getProgramByName(programName: string): Promise<Program | undefined> {
		const q = query<Program, DocumentData>(collection(this.afs, 'mas-programs') as CollectionReference<Program>, where('name', '==', programName));

		const qDocs = await getDocs(q);
		if (!qDocs) return;

		return qDocs.docs.map(m => m.data()).pop() as Program;
	}

	getProgramsAsObservable(): Observable<Program[]> {
		return collectionSnapshots<Program>(
			query<Program, DocumentData>(
				collection(this.afs, 'mas-programs') as CollectionReference<Program>,
				where('status', '==', 'Active'),
				orderBy('status'),
				orderBy('name')
			)
		).pipe(
			map(changes => {
				return changes.map(a => {
					const data = a.data();
					data.id = a.id;
					data.css = 'unselected';
					return data;
				});
			})
		);
	}

	async getProgramsAsPromise(): Promise<Program[]> {
		const q = query<Program, DocumentData>(collection(this.afs, 'mas-programs') as CollectionReference<Program>);
		const qDocs = await getDocs(q);

		if (!qDocs) return [];

		return qDocs.docs.map(m => m.data());
	}

	async getProgramsByMemberships(memberships: string[]): Promise<Program[]> {
		const q = query<Program, DocumentData>(collection(this.afs, 'mas-programs') as CollectionReference<Program>, where('id', 'in', memberships), orderBy('name'));

		const qDocs = await getDocs(q);

		if (!qDocs) return [];

		return qDocs.docs.map(m => m.data());
	}

	async getTestCategory(memberships: string[]): Promise<any> {
		const q = query<Program, DocumentData>(
			collection(this.afs, 'mas-programs') as CollectionReference<Program>,
			where('id', 'in', memberships),
			orderBy('priority', 'desc'),
			limit(1)
		);

		const qDocs = await getDocs(q);

		if (!qDocs) return [];

		return qDocs.docs.map(m => m.data().testCategory);
	}

	async getProgramsWithReservations(): Promise<Program[]> {
		const q = query<Program, DocumentData>(collection(this.afs, 'mas-programs') as CollectionReference<Program>, where('optionReserve', '==', true), orderBy('name'));

		const qDocs = await getDocs(q);

		if (!qDocs) return [];

		return qDocs.docs.map(m => m.data());
	}

	async getProgramsAndSchedules(programsList: string[]): Promise<any[]> {
		if (programsList.length === 0) return [];

		const q = query<Program, DocumentData>(
			collection(this.afs, 'mas-programs') as CollectionReference<Program>,
			where('id', 'in', programsList),
			where('optionReserve', '==', true),
			orderBy('name')
		);

		const qDocs = await getDocs(q);

		if (!qDocs) return [];

		const qData = qDocs.docs.map(m => m.data() as any);

		const programs = qData.map(async m => {
			const schedules = await this.schedule_Service.getSchedulesByProgramAsPromise(m.name).then(schedules => schedules);
			m.schedules = schedules;
			return m;
		});

		return (await Promise.all(programs)) ?? ([] as ProgramAndSchedules[]);
	}

	//TODO test and then replace above
	async getProgramsAndSchedulesNew(programsList: string[]): Promise<any[]> {
		if (programsList.length === 0) return [];

		const q = query<Program, DocumentData>(
			collection(this.afs, 'mas-programs') as CollectionReference<Program>,
			where('id', 'in', programsList),
			where('optionReserve', '==', true),
			orderBy('name')
		);

		const qDocs = await getDocs(q);

		if (!qDocs) return [];

		const programsData: Program[] = qDocs.docs.map(doc => doc.data() as Program);

		const programsWithSchedules = programsData.map(async (program): Promise<any> => {
			const schedules = await this.schedule_Service.getSchedulesByProgramAsPromise(program.name);
			return { ...program, schedules };
		});

		return Promise.all(programsWithSchedules);
	}

	async getProgramsByMembershipProperties(properties: ProgramMember): Promise<Program[]> {
		const q = query<Program, DocumentData>(collection(this.afs, 'mas-programs') as CollectionReference<Program>, where('memberships', 'array-contains', properties));

		const qDocs = await getDocs(q);

		if (!qDocs) return [];

		return qDocs.docs.map(m => m.data());
	}

	async getProgramsWithTestCategory(): Promise<Program[]> {
		const q = query<Program, DocumentData>(collection(this.afs, 'mas-programs') as CollectionReference<Program>);

		const qDocs = await getDocs(q);

		if (!qDocs) return [];

		const docs = qDocs.docs.map(m => m.data());

		const fdocs = docs.filter(f => f.testCategory !== undefined);

		return fdocs;
	}

	async getProgramByTestCategory(category: string): Promise<Program | undefined> {
		const q = query<Program, DocumentData>(collection(this.afs, 'mas-programs') as CollectionReference<Program>, where('testCategory', '==', category));

		const qDocs = await getDocs(q);

		if (!qDocs) return;

		return qDocs.docs.map(m => m.data()).pop() as Program;
	}

	async setProgram(id: string, payload: any) {
		if (payload.css) delete payload.css;
		const ref = doc(this.afs, 'mas-programs', id);
		setDoc(ref, payload, { merge: true });
	}

	async getLegacyLedger(): Promise<Ledger[]> {
		const q = query<Ledger, DocumentData>(collection(this.afs, 'mas-ledger') as CollectionReference<Ledger>, orderBy('timeCreated'));

		const qDocs = await getDocs(q);

		if (!qDocs) return [];

		return qDocs.docs.map(m => {
			const data = m.data();
			data.id = m.id;
			return data;
		});
	}

	async getLegacyBalance(): Promise<Ledger | undefined> {
		const q = query<Ledger, DocumentData>(
			collection(this.afs, 'mas-ledger') as CollectionReference<Ledger>,
			where('description', '==', 'Balance'),
			orderBy('timeCreated', 'desc'),
			limit(1)
		);

		const qDocs = await getDocs(q);

		if (!qDocs) return;

		return qDocs.docs.map(m => m.data()).pop();
	}

	setLedger(id: string, payload: Ledger) {
		const ref = doc(this.afs, 'mas-ledger', id);
		setDoc(ref, payload, { merge: true });
	}

	async createLedger(payload: Ledger): Promise<DocumentReference<DocumentData>> {
		const ref = collection(this.afs, 'mas-ledger');
		return await addDoc(ref, payload).then(doc => doc);
	}

	async deleteLedger(id: string) {
		const ref = doc(this.afs, 'mas-ledger', id);
		return await deleteDoc(ref).then(doc => doc);
	}
}
