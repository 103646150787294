import { Component, OnInit } from '@angular/core';
import { Account } from 'src/app/models/mas-accounts.model';
import { CustomFunctions } from 'src/app/models/global.model';
import { Program } from 'src/app/models/mas-programs.model';
import { Notifications, Schedule, ScheduleElementType } from 'src/app/models/mas-schedules.model';
import { Firebase_Functions_Service } from 'src/app/services/functions.service';
import { MAS_Accounts_Service } from 'src/app/services/mas-accounts.service';
import { MAS_Programs_Service } from 'src/app/services/mas-programs.service';
import { MAS_Schedules_Service } from 'src/app/services/mas-schedules.service';

interface AccountAndTest extends Account {
	tests: { category: string; schedules: SchedulesAndDetails[] }[];
}

interface SchedulesAndDetails extends Schedule {
	details: ScheduleElementType;
}

@Component({
	selector: 'app-programs-testing',
	templateUrl: './programs-testing.component.html',
	styleUrls: ['./programs-testing.component.css'],
})
export class ProgramsTestingComponent implements OnInit {
	public spinnerState = true;
	public accounts: AccountAndTest[] = [];
	private limits!: { name: string; testCategory: string; reservation: number; testing: number }[];

	constructor(
		private accounts_Service: MAS_Accounts_Service,
		private firebaseFunctions: Firebase_Functions_Service,
		private programs_Service: MAS_Programs_Service,
		private schedules_Service: MAS_Schedules_Service
	) {}

	async ngOnInit() {
		try {
			this.spinnerState = true;
			await this.initializeProgramsAndAccounts();
			this.spinnerState = false;
		} catch (error) {
			this.handleError(error, 'ngOnInit', this);
		}
	}

	private async initializeProgramsAndAccounts() {
		const linkedAccounts = this.accounts_Service.linkedAccounts;
		const programsList = this.accounts_Service.myProgramsList;

		const programs = await this.programs_Service.getProgramsByMemberships(programsList);
		this.setLimits(programs);

		const allAccounts = this.getAllAccounts(linkedAccounts);
		await this.setTestCategories(allAccounts, programs);

		this.accounts = allAccounts;
	}

	private setLimits(programs: Program[]): void {
		this.limits = programs.map(m => ({
			name: m.name,
			testCategory: m.testCategory,
			reservation: m.reserveLimit,
			testing: m.maxTestingLimit,
		}));
	}

	private getAllAccounts(linkedAccounts: Account[]): AccountAndTest[] {
		const allAccounts = linkedAccounts.length > 0 ? <AccountAndTest[]>linkedAccounts : <AccountAndTest[]>[];
		const isMember = this.accounts_Service.myAccount.mas.accountSettings.member === 'true';

		if (isMember) allAccounts.push(<AccountAndTest>this.accounts_Service.myAccount);
		return allAccounts;
	}

	private async setTestCategories(accounts: AccountAndTest[], programs: Program[]): Promise<void> {
		await this.setPrimaryTestCategory(accounts, programs);
		await this.setAlternateTestCategory(accounts);
	}

	async setPrimaryTestCategory(accounts: AccountAndTest[], programs: Program[]) {
		const accountUpdates = accounts.map(async account => {
			const accountPrograms = programs.filter(program => program.memberships?.some(m => m.memberId === account.id));

			accountPrograms.sort((a, b) => a.priority - b.priority);

			const category = accountPrograms.pop()?.testCategory ?? '';

			const categorySchedules = await this.schedules_Service.getSchedulesByTestCategories([category]);

			const clonedArray: SchedulesAndDetails[] = JSON.parse(JSON.stringify(categorySchedules));

			account.tests = [{ category: category, schedules: clonedArray }];

			await this.setAttendanceDetails(account);
		});

		return await Promise.all(accountUpdates);
	}

	async setAlternateTestCategory(accounts: AccountAndTest[]) {
		return accounts.map(async account => {
			if (account.mas.accountSettings.alternateTestCategory && account.mas.accountSettings.alternateTestCategory !== 'none') {
				const categorySchedules = await this.schedules_Service.getSchedulesByTestCategories([account.mas.accountSettings.alternateTestCategory]);

				const clonedArray: SchedulesAndDetails[] = JSON.parse(JSON.stringify(categorySchedules));

				account.tests.push({ category: account.mas.accountSettings.alternateTestCategory, schedules: clonedArray });
			}

			this.setAttendanceDetails(account);
		});
	}

	async setAttendanceDetails(account: AccountAndTest) {
		//^ using the test array
		account.tests?.forEach(test => {
			//^ we want to review the schedules

			test.schedules.forEach(schedule => {
				//^ checking enrollment
				const isEnrolled = schedule.attendance?.some(attend => account.id === attend.id) ?? false;

				//^ what are the testing participation limits
				const doDisable: boolean = schedule.attendance && schedule.attendance?.length >= 8 && !isEnrolled ? true : false;

				const detail: ScheduleElementType = {
					attendance: schedule.attendance ?? [],
					css: isEnrolled ? 'selected' : 'unselected',
					cycleName: schedule.cycleName,
					disable: doDisable,
					enrolled: isEnrolled,
					icon: isEnrolled ? 'pi pi-check-circle' : 'pi pi-times-circle',
					id: account.id,
					reserveLimit: 0,
					startDateTime: CustomFunctions.dateToFriendlyDate(schedule.start.dateTime as unknown as Date),
					testingLimits: 8,
				};

				schedule.details = detail;
			});
		});
	}

	async onClick(account: AccountAndTest, schedule: SchedulesAndDetails) {
		//^ let's not be click happy
		if (this.spinnerState || schedule.details.disable) return;
		this.spinnerState = true;

		const name = `${account.names.givenName} ${account.names.familyName}`;

		try {
			if (schedule && !schedule.details.enrolled) {
				await this.register(account.id, name, schedule.id);
			} else if (schedule && schedule.details.enrolled) {
				await this.unregister(account.id, schedule.id);
			}
			await this.initializeProgramsAndAccounts();
		} catch (error) {
			this.handleError(error, 'onClick', schedule);
		} finally {
			this.spinnerState = false;
		}
	}

	async register(accountId: string, accountName: string, scheduleId: string) {
		try {
			const doc = await this.schedules_Service.getScheduleById(scheduleId);

			const value: Notifications = {
				id: this.accounts_Service.myAccount.id,
				name: `${this.accounts_Service.myAccount.names.givenName} ${this.accounts_Service.myAccount.names.familyName}`,
			};

			const sms = this.accounts_Service.myAccount.mas.accountSettings.enableSMS;
			if (sms) value.phone = this.accounts_Service.myAccount.phoneNumbers?.value;

			const email = this.accounts_Service.myAccount.mas.accountSettings.enableEmailReminders;
			if (email) value.email = this.accounts_Service.myAccount.emailAddresses?.value;

			const givenDocument = doc as Schedule;

			const newAttendance = { attended: false, id: accountId, name: accountName, reserved: true, notifications: value };

			if (givenDocument.attendance) {
				givenDocument.attendance.push(newAttendance);
			} else {
				givenDocument.attendance = [newAttendance];
			}

			this.schedules_Service.setScheduleById(scheduleId, givenDocument);
		} catch (error) {
			this.handleError(error, 'register', this);
		}
	}

	async unregister(accountId: string, scheduleId: string) {
		try {
			let givenDocument: Schedule = await this.schedules_Service.getScheduleById(scheduleId);

			if (givenDocument && givenDocument.attendance) {
				givenDocument.attendance = givenDocument.attendance.filter(m => m.id !== accountId);
			}

			await this.schedules_Service.setScheduleById(scheduleId, givenDocument);
		} catch (error) {
			console.error(error);
			this.handleError(error, 'unregister', this);
		}
	}

	async handleError(error: any, caller?: string, object?: any) {
		const errorSummary = caller ? `programs-testing.component.ts => handleError, called from ${caller}` : 'programs-testing.component.ts => handleError';

		this.firebaseFunctions.sendErrorEmail({ summary: errorSummary, detail: object });
	}
}
